import * as React from "react";
import PersonalLayout from "../../../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../../../components/pages/personal/PersonalBody";
import {Divider, Form, Input, message} from "antd";
import styled from "@emotion/styled";
import DarkButton from "../../../../components/buttons/darkButton";
import {useState} from "react";
import Editor from "../../../../components/editor";
import CoverUpload from "../../../../components/pages/personal/CoverUpload";
import auth from "../../../../utils/auth";
import jsonRequest from "../../../../utils/request/jsonRequest";
import {navigate} from "gatsby";
import TitleInput from "../../../../components/editor/titleInput";
import Seo from "../../../../components/seo";

const UploadTitle = styled.div`
  line-height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
`

const UploadDesc = styled.div`
  margin-top: 16px;
  line-height: 22px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
`

const UploadButtons = styled.div`
  margin-top: 12px;
`

const Actions = styled.div`
  margin-top: 32px;
`

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]


const PersonalCaseAdd = () => {
  auth.authorize()
  const [form] = Form.useForm()
  const [name, setName] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const handleFinish = (values: any) => {
    if (submitting) {
      return;
    }
    setSubmitting(true)
    values.coverPath = values.cover.path;
    values.content = JSON.stringify(values.content)
    jsonRequest("/account/case", {
      method: 'POST',
      data: values
    }).then((rsp) => {
      if (rsp.success) {
        setSubmitting(false)
        message.success('保存成功')
        navigate("/personal/resume/case")
      } else {
        message.error(rsp.message)
      }
    }).catch(console.log).finally(() => {
      setSubmitting(false)
    })
  }

  return (
    <Form
      layout="vertical"
      onValuesChange={(_, values) => {
        setName(values.name)
      }}
      onFinish={handleFinish}
      onFinishFailed={({errorFields}) => {
        for (let i = 0; i < errorFields.length; i++) {
          const field = errorFields[i]
          for (let j = 0; j < field.errors.length; j++) {
            message.error(field.errors[j])
            return
          }
        }
      }}
      initialValues={{content: initialValue}}
      {...{form}}
    >
      <PersonalLayout selected='resume'>
        <PersonalBody
          title='添加案例'
          backLink='/personal/resume/case'
          paddingTop={0}
        >
          {/*<Form.Item label='项目名称' name='name' rules={[{required: true, message: '请输入项目名称'}]}>*/}
          {/*  <Input />*/}
          {/*</Form.Item>*/}
          <Form.Item name='content' noStyle required={false} rules={[{required: true, message: '请输入内容'}]}>
            <Editor
              headerRender={(
                <Form.Item
                  name='name'
                  noStyle
                  rules={[{required: true, message: '请输入标题'}]}
                  required={false}
                >
                  <TitleInput/>
                </Form.Item>
              )}
            />
          </Form.Item>
          <Divider />
          <UploadTitle>上传封面</UploadTitle>
          <UploadDesc>封面效果预览</UploadDesc>
          <UploadButtons>
            <Form.Item name='cover' required={false} rules={[{required: true, message: '请上传封面'}]}>
              <CoverUpload name={name} />
            </Form.Item>
          </UploadButtons>
          <Actions>
            <DarkButton onClick={form.submit} loading={submitting}>保存</DarkButton>
          </Actions>
        </PersonalBody>
      </PersonalLayout>
    </Form>
  )
}

export default PersonalCaseAdd;

export const Head = () => <Seo />
